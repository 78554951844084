.chat-box-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
  }
  
  .response-container {
    background-color: #ffffff;
    border-radius: 4px;
    padding: 16px;
    width: 100%;
    max-width: 500px;
    min-height: 100px;
    margin-bottom: 16px;
    box-sizing: border-box;
    overflow-wrap: break-word;
  }
  
  .input-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
  }
  
  .input-text {
    resize: none;
    border-radius: 4px;
    border: 1px solid #cccccc;
    padding: 8px;
    font-size: 14px;
    width: 100%;
    height: 100px;
    margin-bottom: 8px;
    box-sizing: border-box;
  }
  
  button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  @media screen and (max-width: 600px) {
    .chat-box-container {
      padding: 16px;
    }
  }
  